import React from "react";
import { Table, List } from "antd";

type IProps = {
  data: any[];
};

const InteractiveTable = ({ data }: IProps) => {
  const isLifeSciencesPatientsDetected = data.some(
    (d) => d.caregiver || d.caregiverRelationship || d.qualified || d.consent || d.language
  );
  const columns = [
    {
      title: "visitId",
      dataIndex: "visitId",
      key: "visitId",
    },
    {
      title: "date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "timeZone",
      dataIndex: "timeZone",
      key: "timeZone",
    },
    {
      title: "firstName",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "lastName",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "gender",
      dataIndex: "gender",
      key: "gender",
      render: (_, record: any) => (
        <>{typeof record.gender !== "undefined" ? record.gender : "No gender -> not_specify"}</>
      ),
    },
    {
      title: "phoneNumber",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (_, record: any) => (
        <>{typeof record.phoneNumber !== "undefined" ? record.phoneNumber : "No phone -> 12000000000"}</>
      ),
    },
    {
      title: "dob",
      dataIndex: "dob",
      key: "dob",
    },
    {
      title: "city",
      dataIndex: "city",
      key: "city",
    },
    ...(isLifeSciencesPatientsDetected
      ? [
          {
            title: "caregiver",
            dataIndex: "caregiver",
            key: "caregiver",
          },
          {
            title: "caregiverRelationship",
            dataIndex: "caregiverRelationship",
            key: "caregiverRelationship",
          },
          {
            title: "language",
            dataIndex: "language",
            key: "language",
          },
          {
            title: "consent",
            dataIndex: "consent",
            key: "consent",
          },
          {
            title: "qualified",
            dataIndex: "qualified",
            key: "qualified",
          },
        ]
      : []),
    {
      title: "street",
      dataIndex: "street",
      key: "street",
    },
    {
      title: "state",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "zipCode",
      dataIndex: "zipCode",
      key: "zipCode",
    },
    {
      title: "hippa",
      dataIndex: "hippa",
      key: "hippa",
    },
    {
      title: "wearGlasses",
      dataIndex: "wearGlasses",
      key: "wearGlasses",
    },
    {
      title: "wearContactLenses",
      dataIndex: "wearContactLenses",
      key: "wearContactLenses",
    },
    {
      title: "examType",
      dataIndex: "examType",
      key: "examType",
    },
    {
      title: "medicalInsuranceProviderId",
      dataIndex: "medicalInsuranceProviderId",
      key: "medicalInsuranceProviderId",
    },
    {
      title: "medicalSubscriberId",
      dataIndex: "medicalSubscriberId",
      key: "medicalSubscriberId",
    },
    {
      title: "visionInsuranceProviderId",
      dataIndex: "visionInsuranceProviderId",
      key: "visionInsuranceProviderId",
    },
    {
      title: "visionSubscriberId",
      dataIndex: "visionSubscriberId",
      key: "visionSubscriberId",
    },
    {
      title: "smsSubscription",
      dataIndex: "smsSubscription",
      key: "smsSubscription",
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      expandable={{
        expandedRowRender: (record) => (
          <List
            size="small"
            header={null}
            footer={null}
            bordered
            dataSource={record.errors}
            renderItem={(item: string) => <List.Item style={{ color: "red" }}>{item}</List.Item>}
          />
        ),
        rowExpandable: (record) => record.errors && record.errors.length,
      }}
      scroll={{ x: 3500, y: 5000 }}
    />
  );
};

export default InteractiveTable;
